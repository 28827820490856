<template>
	<div class="comment-list">
		<el-card v-for="(comment, index) in data"
				 :key="index"
				 class="comment-item">
			<!-- 已采纳水印 -->
			<div v-if="comment.status === 1"
				 class="accepted-watermark">已采纳
			</div>
			<div class="comment-header">
				<span class="comment-author">
						{{ comment.userName || '' }} / {{ comment.agentName || '' }}
				</span>
				<span class="comment-date">{{ formatTimestamp(comment.createTimeStr, true) }}</span>
			</div>
			<el-divider/>
			<div class="comment-content">
				{{ comment.content }}
			</div>
			<template v-if="comment.sysAttachFileList && comment.sysAttachFileList.length > 0">
				<div class="attribute-item__content attribute-item__col mt10">
					<div v-for="(file, index) in comment.sysAttachFileList"
						 :key="index">
									<span v-if="getFileType(file.fileAddr) === 'video'">
										<VideoPlayer :videoLink="file.fileAddr"
													 class="attribute-item__video"></VideoPlayer>
									</span>
						<span v-else-if="getFileType(file.fileAddr) === 'image'">
										<el-image
											:src="file.fileAddr"
											fit="scale-down"
											:preview-src-list="getImages(comment)"
											class="attribute-item__image"
										></el-image>
									</span>
					</div>
				</div>
				<div class="attribute-item__content mt10">
					<div v-for="(file, index) in comment.sysAttachFileList"
						 :key="index">
									<span v-if="getFileType(file.fileAddr) !== 'video' && getFileType(file.fileAddr) !== 'image'">
										<el-link icon="el-icon-link"
												 type="primary"
												 :href="file.fileAddr"
												 target="_blank">
										{{ file.fileName }} </el-link>
									</span>
					</div>
				</div>
			</template>
			<div class="comment-footer">
				<el-button type="text"
						   class="info-text"
						   v-if="checkShowAccept(comment)"
						   @click="handleAccept(comment)">采纳
				</el-button>
				<el-button type="text"
						   class="info-text"
						   v-if="userID !== comment.userId"
						   @click="handleReply(comment)">评论
				</el-button>
				<el-button type="text"
						   class="info-text"
						   v-if="checkShowWarn"
						   @click="handleWarn(comment)">{{ comment.warnFlag === 1 ? '取消警告' : '警告' }}
				</el-button>
				<el-button type="text"
						   class="info-text error-color"
						   v-if="chreckShowDel"
						   @click="handleDel(comment)">删除
				</el-button>
			</div>
			<div v-if="comment.sonReplys.length > 0"
				 class="replies">
				<el-card v-for="(reply, replyIndex) in comment.sonReplys"
						 :key="replyIndex"
						 class="reply-item">
					<div class="reply-header">
						<span class="reply-author"
							  v-if="reply.parentId === reply.replyId">{{ reply.userName }} / {{ reply.agentName || '' }}</span>
						<span class="reply-author"
							  v-else>{{ reply.userName }} / {{ reply.agentName || '' }}
							<span class="mr5 ml5 reply-dev">回复</span>
							{{ reply.replyUserName }} / {{ reply.replyAgentName || '' }}</span>
						<span class="reply-date">{{ formatTimestamp(reply.createTimeStr, true) }}</span>
					</div>
					<el-divider/>
					<div class="reply-content">
						{{ reply.content }}
					</div>
					<template v-if="reply.sysAttachFileList && reply.sysAttachFileList.length > 0">
						<div class="attribute-item__content attribute-item__col mt10">
							<div v-for="(file, index) in reply.sysAttachFileList"
								 :key="index">
									<span v-if="getFileType(file.fileAddr) === 'video'">
										<VideoPlayer :videoLink="file.fileAddr"
													 class="attribute-item__video"></VideoPlayer>
									</span>
								<span v-else-if="getFileType(file.fileAddr) === 'image'">
										<el-image
											:src="file.fileAddr"
											fit="scale-down"
											:preview-src-list="getImages(reply)"
											class="attribute-item__image"
										></el-image>
									</span>
							</div>
						</div>
						<div class="attribute-item__content mt5">
							<div v-for="(file, index) in reply.sysAttachFileList"
								 :key="index">
									<span v-if="getFileType(file.fileAddr) !== 'video' && getFileType(file.fileAddr) !== 'image'">
										<el-link icon="el-icon-link"
												 type="primary"
												 :href="file.fileAddr"
												 target="_blank">
										{{ file.fileName }} </el-link>
									</span>
							</div>
						</div>
					</template>
					<div class="comment-footer">
						<el-button type="text"
								   class="info-text"
								   v-if="userID !== reply.userId"
								   @click="handleReply(reply)">评论
						</el-button>
						<el-button type="text"
								   class="info-text"
								   v-if="checkShowWarn"
								   @click="handleWarn(reply)">{{ reply.warnFlag === 1 ? '取消警告' : '警告' }}
						</el-button>
						<el-button type="text"
								   class="info-text error-color"
								   v-if="chreckShowDel"
								   @click="handleDel(reply)">删除
						</el-button>
					</div>
				</el-card>
			</div>
		</el-card>
		<commentAdd ref="commentAdd"
					:id="id"
					@refresh="handleRefresh"></commentAdd>
	</div>
</template>

<script>
import commentAdd from "@/views/bbs/components/commentAdd.vue";
import VideoPlayer from '@/components/VideoPlayer'

export default {
	name: 'Comment',
	components: {commentAdd, VideoPlayer},
	props: {
		data: {
			type: Array,
			default: () => []
		},
		// 主题id
		id: {
			type: String,
			default: ''
		},
		// 问题类型 1：问题
		type: {
			type: String,
			default: ''
		},
		// 状态 0: 待处理
		status: {
			type: String,
			default: ''
		},
		// 创建者ID
		createId: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			// 权限
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			userID: JSON.parse(window.sessionStorage.getItem('userInfo')).id
		};
	},
	created() {
	},
	computed: {
		// 是否显示警告按钮
		checkShowWarn(){
			if (this.type === '1' || this.type === '2') {
				if (this.perms.includes('home:bbs:content:commentpub')) {
					return true
				}
			} else if (this.type === '3') {
				if (this.perms.includes('home:bbs:case:commentpub')) {
					return true
				}
			}
			return false
		},
		// 是否显示删除按钮
		chreckShowDel(){
			if (this.type === '1' || this.type === '2') {
				if (this.perms.includes('home:bbs:content:commentdelete')) {
					return true
				}
			} else if (this.type === '3') {
				if (this.perms.includes('home:bbs:case:commentdelete')) {
					return true
				}
			}
			return false
		},
	},
	methods: {
		// 是否显示采纳按钮
		checkShowAccept(value) {
			return this.type === '1' && this.status === '0' && this.createId === this.userID && value.userId !== this.createId
		},
		// 评论
		handleReply(comment) {
			const newForm = {
				...comment,
				parentId: comment.parentId === '0' ? comment.id : comment.parentId,
				replyId: comment.id,
				replyUserName: comment.userName,
				mainInfoId: this.id
			}
			this.$refs.commentAdd.show('', newForm)
		},
		formatTimestamp(ms, datetime) {
			const _fmt = datetime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
			return this.$moment(new Date(ms)).format(_fmt)
		},
		// 警告评论
		handleWarn(comment) {
			let title = comment.warnFlag === 1 ? '确定要取消警告吗?' : '确定要警告吗?'
			this.$confirm(title, {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this
				that.$request.post(
					'bbsInfoReplyCommentGood',
					true, {
						id: comment.id,
						warnFlag: comment.warnFlag === 1 ? 0 : 1
					},
					function (r) {
						if (r.code == "0") {
							that.$emit("refresh")
							that.$message.success('操作成功')
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			}).catch(() => {
			})
		},
		// 删除评论
		handleDel(comment) {
			this.$confirm('确认要删除吗?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this
				that.$request.post(
					'bbsInfoReplyCommentDel',
					true, {
						id: comment.id
					},
					function (r) {
						if (r.code == "0") {
							that.$emit("refresh")
							that.$message.success('删除成功')
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			}).catch(() => {
			})
		},
		handleRefresh() {
			this.$emit("refresh")
		},
		// 文件类型
		getFileType(url) {
			const extension = url?.split('.').pop().toLowerCase() ?? ''
			const videoExtensions = ['mp4', 'avi', 'mov', 'mkv', 'flv'];
			const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
			if (videoExtensions.includes(extension)) {
				return 'video';
			} else if (imageExtensions.includes(extension)) {
				return 'image';
			} else {
				return 'file';
			}
		},
		// 获取图片链接
		getImages(comment) {
			const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
			return comment.sysAttachFileList
				.filter(item => {
					const extension = item.fileAddr?.split('.').pop().toLowerCase();
					return imageExtensions.includes(extension);
				})
				.map(item => item.fileAddr);
		},
		// 采纳评论
		handleAccept(comment) {
			this.$confirm('确定要采纳此评论吗?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this
				that.$request.post(
					'bbsInfoReplyCommentAdopt',
					true, {
						id: comment.id,
					},
					function (r) {
						if (r.code == "0") {
							that.$emit("refresh")
							that.$message.success('操作成功')
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			}).catch(() => {
			})
		},
	}
};
</script>

<style scoped>

.comment-list {
	display: flex;
	flex-direction: column;

	.el-card__body {
		padding: 14px;
	}
}

.comment-item {
	margin-bottom: 16px;
	position: relative; /* 确保水印相对于 el-card 定位 */
}

.accepted-watermark {
	position: absolute;
	top: 20px;
	left: 13px;
	background-color: transparent;
	color: rgba(255, 0, 0, 0.7); /* 红色，透明度为 0.7 */
	font-size: 16px;
	font-weight: bold;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	text-align: center;
	transform: rotate(-45deg);
	border: 2px solid rgba(255, 0, 0, 0.7); /* 边框颜色也添加透明度 */
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.comment-footer,
.reply-footer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 18px;
}

.comment-header,
.reply-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.comment-author,
.reply-author {
	font-weight: bold;
	font-size: 15px;
	color: #117FFC;
}

.comment-author_left {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.comment-author_agent {
	font-weight: bold;
	font-size: 13px;
}

.comment-date,
.reply-date {
	color: #999;
	font-size: 13px;
}

.reply-dev {
	color: #999;
	font-size: 13px;
}

.comment-content,
.reply-content {
	margin-top: 8px;
	font-size: 15px;
	position: relative; /* 确保水印相对于 el-card 定位 */
}


.replies {
	margin-left: 16px;
}

.reply-item {
	margin-top: 8px;
}


.attribute-item__col {
	width: 100%;
	height: 100%;
	display: flex;
	column-gap: 5px;
	row-gap: 5px;
}

.attribute-item__image {
	width: 160px;
	height: 160px;
	object-fit: contain;
	border: #f0f0f0 1px solid;
}

.attribute-item__video {
	width: 160px;
	height: 160px;
	object-fit: cover;
	border: #f0f0f0 1px solid;
}

::v-deep .attribute-item__content .ql-snow .ql-editor {
	font-size: 15px !important;
	color: red;
}

</style>
