<template>
	<el-dialog
		ref="auditDialog"
		:title="title"
		:visible.sync="visible"
		width="40%"
	>
		<el-form ref="replyForm" :model="form" :rules="rules" label-position="top">
			<el-form-item :label="remarkLabel" prop="content">
				<el-input v-model="form.content" type="textarea" placeholder="请输入" ></el-input>
			</el-form-item>
			<el-form-item label="附件" prop="fileIdList">
				<el-upload
					:action="fileUploadPath"
					:headers="uploadHeaders"
					:multiple="true"
					:show-file-list="true"
					:file-list="attachFileList"
					:limit="fileLimit"
					:before-upload="handleBeforeUpload"
					:on-success="handleFileUploaded"
					:on-remove="handleFileRemove"
					accept=".mp4,.wmv,.avi,.m4v,.mov,.jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx,.zip"
				>
					<el-button icon="el-icon-paperclip" :disabled="attachFileList.length >= fileLimit">上传文件</el-button>
					<div slot="tip" class="el-upload__tip">(最多可上传{{ fileLimit }}个, 每个文件不超过50M)</div>
				</el-upload>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
            <el-button @click="handleCancel"> 取消 </el-button>
            <el-button type="primary" :loading="loading" @click="handleApproval"> 确定 </el-button>
        </span>
	</el-dialog>
</template>

<script>
import defaultSettings from "@/settings"
import { debounce } from '@/utils/debounce'

const url = defaultSettings.hostName

export default {
	name: 'ApplyConfirm',
	props: {
		// 主题id
		id: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			visible: false,
			loading: false,
			action: "",
			form: {
				parentId: '',      // 直接回复主题时为0  回复评论时 都是最上级回复的ID(根节点的ID)
				replyId: '',       // 回复的ID,
				replyUserName: '', // 回复人
				mainInfoId:'',	   // 主题ID
				fileFlag: '',	   // 是否含有附件 0：否 1：是 如果有附件 去附件表中查询
				content: '',	   // 回复内容
				fileIdList: [],     // 附件
			},
			attachFileList: [],
			// 上传附件路径
			fileUploadPath: '',
			rules: {
				content: [
					{ required: true, message: "请输入", trigger: "blur" },
					{
						min: 1,
						max: 500,
						message: '评论内容长度须在1-500范围内',
						trigger: 'blur'
					}
				],
				fileIdList: [
					{ required: false, message: "请上传附件", trigger: "change" },
				]
			},
		}
	},
	computed: {
		title() {
			return "发表评论"
		},
		remarkLabel() {
			return "评论内容"
		},
		fileLimit() {
			// 最大上传文件数
			return 10
		},
		uploadHeaders() {
			const token = sessionStorage.getItem('token') || ''
			return {
				token: token
			}
		},
	},
	methods: {
		show(action, data) {
			this.action = action
			this.attachFileList = []
			this.form = {
				parentId: data.parentId || 0,
				replyId: data.replyId || 0,
				replyUserName: data.replyUserName || '',
			}
			this.loading = false
			this.$refs.replyForm && this.$refs.replyForm.resetFields()
			this.$nextTick(() => { this.visible = true })
		},
		handleCancel() {
			this.visible = false
		},
		handleApproval() {
			let that = this
			that.$refs['replyForm'].validate((valid, fields) => {
				if (!valid) {
					return
				}
				that.form.mainInfoId = this.id
				that.loading = true
				that.$request.post(
					"bbsInfoReplyAdd",
					false,
					that.form,
					function (r) {
						if (r.code == "0") {
							that.visible = false
							that.$emit("refresh")
						} else {
							that.loading = false
							that.$message.error(r.msg)
						}
					},
					function () {
						that.loading = false
					}
				)
			})
		},
		handleBeforeUpload(file) {
			// 上传前检查
			const limitSize = file.size / 1024 / 1024 <= 50
			if (!limitSize) {
				this.$message.warning('附件大小不能超过50M')
				return false
			}
			//  文件/图片上传路径
			const extension = file.name.split('.').pop().toLowerCase();

			const videoExtensions = ['mp4', 'wmv', 'avi', 'm4v', 'mov'];
			if (!videoExtensions.includes(extension)) {
				//  文件/图片上传路径
				return new Promise((resolve) => {
					this.$nextTick(() => {
						this.fileUploadPath = url + "bsi/sysAttachFile/addRepairFileOss"
						resolve();
					})
				})
			} else {
				return new Promise((resolve) => {
					// 视频上传路径
					this.$nextTick(() => {
						this.fileUploadPath = url + "bsi/sysAttachFile/addVideoFileOss"
						resolve();
					})
				})
			}
		},
		// 上传附件成功
		handleFileUploaded(response, file, fileList) {
			this.attachFileList = fileList
			this.form.fileIdList = fileList.map(item => item.response.data.id)
		},
		handleFileRemove(file, fileList) {
			this.attachFileList = fileList
			this.form.fileIdList = fileList.map(item => item.response.data.id)
		},
	}
}
</script>

<style scoped lang="scss">

</style>
