<template>
	<div class="page edit-page" :style="height">
		<div class="top-wrapper">
			<div class="title"> {{85 > scrollTop ? title : ''}} </div>
			<div class="actions">
				<el-button type="primary" plain size="small" class="cancel" @click="goBack"> 返回 </el-button>
			</div>
		</div>

		<div class="content-wrapper">
			<el-row :gutter="24" type="flex" class="content-row">
				<el-col>
					<div class="detail-container pa20 pb0">
						<div class="attribute-item">
							<div class="attribute-item__label"> 标题 </div>
							<div class="attribute-item__content">
								<span> {{ data.title || '' }} </span>
							</div>
						</div>
						<el-divider />

						<div class="attribute-item">
							<div class="attribute-item__label"> 机种分类 </div>
							<div class="attribute-item__content">
								<span> {{ data.deviceNames || '' }} </span>
							</div>
						</div>
						<el-divider />

						<div class="attribute-item">
							<div class="attribute-item__label"> 机型分类 </div>
							<div class="attribute-item__content">
								<span> {{ data.deviceInfo || '' }} </span>
							</div>
						</div>
						<el-divider />

						<div class="attribute-item">
							<div class="attribute-item__label"> 机型名称 </div>
							<div class="attribute-item__content">
								<span> {{ data.modelInfo || '' }} </span>
							</div>
						</div>
						<el-divider />

						<div class="attribute-item">
							<div class="attribute-item__label"> 问题类型 </div>
							<div class="attribute-item__content">
								<span> {{  getQuesTypeText() }} </span>
							</div>
						</div>
						<el-divider />

						<div class="attribute-item">
							<div class="attribute-item__label"> 图片/视频链接 </div>
							<div class="attribute-item__content">
								<div v-for="(firmware, index) of data.firmwareList" :key="index">
									<el-link icon="el-icon-link" @click="handleOpenWindow(firmware.firmwareLink)" type="primary">
										{{ firmware.firmwareName }} </el-link>
								</div>
							</div>
						</div>
						<el-divider />

						<div class="attribute-item">
							<div class="attribute-item__label"> 附件 </div>
							<div class="attribute-item__content attribute-item__col">
								<div v-for="(file, index) in data.sysAttachFileList" :key="index">
									<span v-if="getFileType(file.fileAddr) === 'video'">
										<VideoPlayer :videoLink="file.fileAddr" class="attribute-item__video"></VideoPlayer>
									</span>
									<span v-else-if="getFileType(file.fileAddr) === 'image'">
										<el-image
											:src="file.fileAddr"
											fit="scale-down"
											:preview-src-list="getImages()"
											class="attribute-item__image"
										></el-image>
									</span>
								</div>
							</div>
							<div class="attribute-item__content mt10">
								<div v-for="(file, index) in data.sysAttachFileList" :key="index">
									<span v-if="getFileType(file.fileAddr) !== 'video' && getFileType(file.fileAddr) !== 'image'">
										<el-link icon="el-icon-link" type="primary" :href="file.fileAddr" target="_blank">
										{{ file.fileName }} </el-link>
									</span>
								</div>
							</div>
						</div>
						<el-divider />

						<div class="attribute-item">
							<div class="attribute-item__label"> 正文 </div>
							<div class="attribute-item__content ">
								<RichTextDisplay :content="data.content" style="font-size: 15px;"></RichTextDisplay>
							</div>
						</div>
						<el-divider />

						<template v-if="data.addContent || data.sysAttachFileAddList">
							<div class="attribute-item" v-if="data.addContent">
								<div class="attribute-item__label" style="color: red;font-size: 16px;"> 追加内容 </div>
								<div class="attribute-item__content ">
									<RichTextDisplay :content="data.addContent"></RichTextDisplay>
								</div>
							</div>
							<div class="attribute-item" v-if="data.sysAttachFileAddList">
								<div class="attribute-item__content attribute-item__col">
									<div v-for="(file, index) in data.sysAttachFileAddList" :key="index">
									<span v-if="getFileType(file.fileAddr) === 'video'">
										<VideoPlayer :videoLink="file.fileAddr" class="attribute-item__video"></VideoPlayer>
									</span>
										<span v-else-if="getFileType(file.fileAddr) === 'image'">
										<el-image
											:src="file.fileAddr"
											fit="scale-down"
											:preview-src-list="getImages()"
											class="attribute-item__image"
										></el-image>
									</span>
									</div>
								</div>
								<div class="attribute-item__content">
									<div v-for="(file, index) in data.sysAttachFileAddList" :key="index">
									<span v-if="getFileType(file.fileAddr) !== 'video' && getFileType(file.fileAddr) !== 'image'">
										<el-link icon="el-icon-link" type="primary" :href="file.fileAddr" target="_blank">
										{{ file.fileName }} </el-link>
									</span>
									</div>
								</div>
							</div>
							<el-divider />
						</template>

						<div class="attribute-item">
							<div class="attribute-item__hand">
								<span class="attribute-item__label mb10">评论列表</span>
								<el-button
									type="text"
									class="info-text"
									v-if="commentFlag"
									@click="handleCommentAdd()"
								>  评论  </el-button>
							</div>
							<div class="attribute-item__content">
								<comment :type="data.type" :status="data.status" :createId="data.createId" :data="comments" :id="id" @refresh="handleReplyList"></comment>
							</div>
							<div class="pagination-wrapper pa25">
								<Pagination :page.sync="commentQuery.pageNo" :pageSize.sync="commentQuery.pageSize" :total="total"
											@sizeChange="handleSizeChange" @currentChange="handleCurrentChange"></Pagination>
							</div>
						</div>
						<el-divider />
					</div>
				</el-col>
			</el-row>
		</div>
		<commentAdd ref="commentAdd" :id="id" @refresh="handleReplyList"></commentAdd>
	</div>
</template>

<script>
import RichTextDisplay from '@/components/RichText/RichTextDisplay.vue'
import comment from "@/views/bbs/components/comment.vue";
import { bbsQuesTypeOptions } from "@/constant/options"
import FileList from "@/views/claimantManagement/repairRequest/components/FileList.vue";
import VideoPlayer from '@/components/VideoPlayer'
import commentAdd from "@/views/bbs/components/commentAdd.vue";
import Pagination from '@/components/Pagination'

export default {
	components: {
		FileList,
		RichTextDisplay,
		comment,
		VideoPlayer,
		commentAdd,
		Pagination
	},
	data() {
		return {
			// ID
			id: '',
			cached: {
				pageNo: 1
			},
			data: {
				title: '',
				type: '', // 类型 1：问题 2：经验 3: 案例
				content: '',
				videoLinks: [], // 图片/视频链接
				modelInfo: '', //机型名称
				deviceInfo: '', //机型分类
				deviceNames: '', //机种分类
				quesType: '',
				fileFlag: '', // 是否有附件
				videoFileFlag: '', // 是否有视频
			},
			commentQuery: {
				pageNo: 1,
				pageSize: 10,
				mainInfoId: '',
			},
			comments: [],
			height: '',
			scrollTop: 0,
			total: 0,
			// 缓存参数
			cacheParams: {},
			// 是否允许评论
			commentFlag: false
		}
	},
	created() {
		// 案例管理
		this.isCase = this.$route.query.menuAction === 'caseManagement';

		this.id = this.$route.query.id
		if (this.id) this.getDetail()
		// 缓存参数
		if (this.$route.params) {
			this.cacheParams = this.$route.params
		}
		this.handleReplyList()

		this.height = 'min-height: ' + (window.innerHeight - 180) + 'px'
	},
	computed: {
		title() {
			if (this.isCase) {
				return '案例详细'
			} else {
				return '内容详细'
			}
		},
	},
	methods: {
		getDetail() {
			if (!this.id) return

			let that = this
			that.$request.post(
				"bbsInfoReplyInfo",
				true,
				{
					id: that.id
				},
				function (r) {
					if (r.code == "0") {
						that.data = r.data
						that.data.videoLinks = r.data.videoLinks ? r.data.videoLinks?.split(',') : []
						that.data.sysAttachFileList = r.data.sysAttachFileList || []
						that.data.type = String(that.data.type)
						that.data.status = String(that.data.status)
						that.data.createId = String(that.data.createId)
					} else {
						that.$message.error(r.msg)
					}
				}
			)
		},
		// 下载
		download() {
			const name = this.data.subject + '.zip'

			let that = this
			that.$request.download(
				"dataFileDownload",
				true, {
					linkedId: that.id,
					linkedTable: 'data_notice'
				},
				function (res) {
					if (res) {
						let blob = new Blob([res], {
							type: 'application/zip'
						})
						let url = window.URL.createObjectURL(blob)
						const link = document.createElement('a') // 创建a标签
						link.href = url
						link.download = name // 重命名文件
						link.click()
						URL.revokeObjectURL(url) // 释放内存
					} else {
						that.$message.error(res.msg)
					}
				}
			)
		},
		goBack() {
			this.$router.push({
				name: this.$route.query.menuAction,
				params: {
					...this.cacheParams
				}
			})
		},
		handleLink(url) {
		},
		// 处理吸顶时title隐藏
		handleScroll() {
			this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
		},
		// 问题类型
		getQuesTypeText(val) {
			return bbsQuesTypeOptions.find(item => item.type === val)?.name || '';
		},
		// 文件类型
		getFileType(url) {
			const extension = url?.split('.').pop().toLowerCase() ?? ''
			const videoExtensions = ['mp4', 'wmv', 'avi', 'm4v', 'mov'];
			const imageExtensions = ['jpg', 'jpeg', 'png'];
			if (videoExtensions.includes(extension)) {
				return 'video';
			} else if (imageExtensions.includes(extension)) {
				return 'image';
			} else {
				return 'file';
			}
		},
		// 获取图片链接
		getImages() {
			const imageExtensions = ['jpg', 'jpeg', 'png'];
			return this.data.sysAttachFileList
				.filter(item => {
					const extension = item.fileAddr?.split('.').pop().toLowerCase();
					return imageExtensions.includes(extension);
				})
				.map(item => item.fileAddr);
		},
		// 评论 新增
		handleCommentAdd() {
			const newForm = {
				...this.data,
				parentId: 0
			}
			this.$refs.commentAdd.show('', newForm)
		},
		// 问答列表
		handleReplyList() {
			if (!this.id) return
			let that = this
			that.commentQuery.mainInfoId = that.id
			that.$request.post(
				"bbsInfoReplyList",
				true,
				that.commentQuery,
				function (r) {
					if (r.code == "0") {
						that.comments = r.data?.list || []
						that.total = r.data?.totalCount || 0

						const userInfoStr = window.sessionStorage.getItem('userInfo')
						if (!userInfoStr) that.commentFlag = false
						const userInfo = JSON.parse(userInfoStr)
						if (!userInfo) that.commentFlag = false
						const id = userInfo.id.toString()
						if (that.comments.length) {
							// 从 this.comments 过滤出相同的 userId 与 id 相同的数据
							that.commentFlag = !that.comments.filter(item => item.userId === id).length;
						} else {
							that.commentFlag = true
						}
						console.log(that.commentFlag);
					} else {
						that.$message.error(r.msg)
					}
				}
			)
		},
		// 每页大小变化
		handleSizeChange() {
			this.handleReplyList()
		},
		// 当前页码变化
		handleCurrentChange() {
			this.handleReplyList()
		},
		// 打开新标签页
		handleOpenWindow(rul) {
			// 检查当前url 和 域名 是否相同
			if (window.location.hostname !== new URL(rul).hostname) {
				window.open(rul);
				return;
			}

			// 浏览器标签页共享数据
			const sessionStorage = window.sessionStorage;
			const localStorage = window.localStorage;
			let vardata, key, value;
			if (sessionStorage.length) {
				// 获取sessionStorage数据
				vardata = JSON.parse(localStorage.getItem('sessionStorage'));
				for (key in vardata) {
					sessionStorage.setItem(key, vardata[key]);
				}
				localStorage.removeItem('sessionStorage');
			}
			// 跳转新的标签页打开页面
			window.open(rul);
		},
	},
	mounted() {
		window.addEventListener('scroll',this.handleScroll);
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll)
	}
}

</script>

<style lang="scss" scoped>
.page {
	background-color: white;
}
.attribute-item__content {
	font-size: 15px !important;
}

.attribute-item__col {
	width: 100%;
	height: 100%;
	display: flex;
	column-gap: 5px;
	row-gap: 5px;
}

.attribute-item__image {
	width: 160px;
	height: 160px;
	object-fit: contain;
	border: #f0f0f0 1px solid;
}

.attribute-item__video {
	width: 160px;
	height: 160px;
	object-fit: cover;
	border: #f0f0f0 1px solid;
}

.attribute-item__hand {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.table-btn {
	padding: 5px;
	font-weight: bold;
	font-size: 12px;
	border: none;
	margin-left: 0;
	text-decoration: underline;
}
</style>
