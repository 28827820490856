<template>
   <div class="file-list">
       <div class="file-item" v-for="(file, index) in fileList" :key="index">
           <div class="file-item__prepend"> {{ file.name || file.fileName }} </div>
           <div class="file-item__append">
               <i class="el-icon-download" @click="download(file)"></i>
               <i v-if="closable" class="el-icon-close" @click="remove(file)"></i>
           </div>
       </div>
   </div>
</template>

<script>
export default {
    props: {
        fileList: {
            type: Array,
            default: () => []
        },
        closable: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        download(file) {
            const link = document.createElement('a')
            link.style.display = 'none'
            link.target = '_blank'
            link.href = file.url || file.fileAddr
            link.download = ''
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        },
        remove(file) {
            this.$emit('onRemove', file)
        }
    }
}
</script>

<style scoped lang="scss">
.file-list {
    .file-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 10px;
        background-color: rgba(64, 158, 255, 0.3);
        border-radius: 6px;

        &:nth-of-type(n+2) {
            margin-top: 4px;
        }
        .file-item__prepend {
            text-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgba(0, 0, 0, .8);
        }
        .file-item__append {
            padding-left: 10px;
            display: flex;
            align-items: center;

            i {
                font-size: 18px;
                cursor: pointer;
                color: rgba(0, 0, 0, .6);

                &:nth-of-type(n+2) {
                    margin-left: 4px;
                }
                &:hover {
                    color: rgba(0, 0, 0, 1);
                }
            }
        }
    }
}
</style>
